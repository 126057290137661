<template>
    <div class="bgColor">
        <gr-pc-home v-if="runtimeConfig?.public.engine == 'gr'" />
        <gt-pc-home v-if="runtimeConfig?.public.engine == 'gt'" />
        <div class="publicBody" v-if="runtimeConfig?.public.engine == 'real'">
            <div class="logo">
                <el-icon :size="304" style="color: #0f0f0f">
                    <IconLogo3 />
                </el-icon>
            </div>
            <div class="contentBox">
                <div class="left">
                    <div class="homeVideo">
                        <video poster="~/assets/img/pchomevideoposter.jpg" disablePictureInPicture playsinline autoplay muted loop :controls="false">
                            <source src="~/assets/img/pchomevideo.mp4" type="video/mp4" />
                        </video>
                    </div>
                </div>
                <div class="right">
                    <div class="bigtitle">HOW ATTRACTIVE AM I?</div>
                    <div class="title">Take a video with a wink and receive genuine ratings from 100% real people in our community! No Filters, Lenses, or AI Interference.</div>
                    <div class="title">Your data and videos are secure with us.</div>
                    <div class="mobiledevice"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    const runtimeConfig = useRuntimeConfig();
</script>

<style scoped lang="scss">
.publicBody {
    width: 1148px;
    margin: auto;
    min-height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
    padding-bottom: 84px;
    .logo {
        margin-top: 50px;
        width: 305px;
        height: 31px;
        i {
            color: rgb(18, 18, 18);
            width: 305px;
            height: 31px;
        }
    }
    .contentBox {
        margin-top: 50px;
        display: flex;
        justify-content: flex-start;
        overflow: hidden;
        .left .homeVideo {
            width: 350px;
            height: 758px;
            flex-shrink: 0;
            position: relative;
            overflow: hidden;
            border-radius: 40px;
        }
        .left .homeVideo video {
            width: 100%;
            height: 100%;
            position: absolute;
            position: absolute;
            left: 0;
            top: 0;
            object-fit: cover;
            object-position: top;
            background-color: #f0f0f0;
        }
        .right {
            margin-left: 120px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-height: 460px;
            .title {
                margin-top: 24px;
                font-size: 32px;
                font-family: "Anton-Regular";
                font-weight: 400;
                color: $mainColor;
                line-height: 48px;
                letter-spacing: 1px;
            }
            .bigtitle {
                font-size: 48px;
                font-family: "Anton-Regular";
                font-weight: 400;
                color: #f35600;
                line-height: 72px;
                letter-spacing: 1px;
            }
            .mobiledevice {
				width: 375px;
				height: 166px;
                background: url("../../../assets/img/mobile_device.png");
                background-size: 100% 100%;
                overflow: hidden;
                clear: both;
                margin-top: 8px;
            }
            .tip {
                font-family: Poppins-Light;
                font-size: 20px;
                color: #161616;
                line-height: 32px;
            }
        }
    }
}
.bgColor{
    height: 100%;
    width: 100%;
    background-color: #fff;
}
</style>
